import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';

const NavBar = () => {
    return (
        <Header
            containerClassName="container-fluid"
            wrapperClassName={style.wrapper}
            logoClassName={style.logo}
            secondaryNavLinks="mainNav"
        />
    );
};

export default NavBar;
